<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
	export default {
		name: 'App',
		// 解决IE浏览器路由跳转时不生效
		mounted() {
			function checkIE() {
				return (
					'-ms-scroll-limit' in document.documentElement.style &&
					'-ms-ime-align' in document.documentElement.style
				)
			}

			if (checkIE()) {
				window.addEventListener(
					'hashchange',
					() => {
						const currentPath = window.location.hash.slice(1)
						if (this.$route.path !== currentPath) {
							this.$router.push(currentPath)
						}
					}, false)
			}
		},
		watch: {
			// $route: function (to) {

			//   if (to.name.indexOf('_p') > 0 && !this.$pc) {
			//     this.$router.replace(to.name.split('_p')[0])
			//     //电脑转手机端
			//     //域名为index_p去掉_p变成手机端index路由
			//     if (to.name=="index_p") {
			//       let str = to.name.split("_p")[0];
			//       let str2 = '/'+str;
			//       this.$router.push(str2);
			//     }
			//   } else if (to.name.indexOf('_p') < 0 && this.$pc) {
			//     //手机端转电脑
			//     //在路由尾巴添加_p变成电脑端
			//     this.$router.replace(to.name + '_p')
			//   }
			// }
		}
	}
</script>



<style lang="scss">
	.el-popup-parent--hidden {
		padding-right: 0 !important;
	}

	img {
		display: block;
	}

	::-webkit-scrollbar {
		/*滚动条整体样式*/
		/*高宽分别对应横竖滚动条的尺寸*/
		width: 6px;
		height: 1px;
	}

	::-webkit-scrollbar-thumb {
		/*滚动条里面小方块*/
		border-radius: 10px;
		background: #ccc;
	}

	::-webkit-scrollbar-track {
		/*滚动条里面轨道*/
		border-radius: 10px;
		background: #fff;
	}
</style>